// Generated by Framer (7f988a2)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zJxbccj4H"];

const serializationHash = "framer-YlqK2"

const variantClassNames = {zJxbccj4H: "framer-v-1yyfgca"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zJxbccj4H", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1yyfgca", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"zJxbccj4H"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-hv0tyu"} data-framer-name={"stroke"} layout={"position"} layoutDependency={layoutDependency} layoutId={"zHFjqtm2s"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 26 32\"><path d=\"M 25 13 C 25 17.5 22.6 21.4 19 23.35 C 19 23.35 18.625 27.25 18.25 28.75 C 17.5 31.75 8.5 31.75 7.75 28.75 C 7.374 27.245 7 23.35 7 23.35 C 3.4 21.4 1 17.5 1 13 C 1 6.4 6.4 1 13 1 C 19.6 1 25 6.4 25 13 Z\" fill=\"transparent\" stroke-width=\"2\" stroke=\"rgb(10,13,15)\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"\"></path></svg>"} svgContentId={12462721043} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YlqK2.framer-wrfpkk, .framer-YlqK2 .framer-wrfpkk { display: block; }", ".framer-YlqK2.framer-1yyfgca { height: 32px; overflow: hidden; position: relative; width: 26px; }", ".framer-YlqK2 .framer-hv0tyu { flex: none; height: 32px; left: calc(50.00000000000002% - 26px / 2); position: absolute; top: calc(50.00000000000002% - 32px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 26
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDOU3hJdai: React.ComponentType<Props> = withCSS(Component, css, "framer-YlqK2") as typeof Component;
export default FramerDOU3hJdai;

FramerDOU3hJdai.displayName = "stroke";

FramerDOU3hJdai.defaultProps = {height: 32, width: 26};

addFonts(FramerDOU3hJdai, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})